import { h } from 'preact';

import { DealerProvider, useDealer } from '../providers/DealerProvider';

import SiteWrapper from '../components/siteWrapper';
import Header from './header';
import Footer from './footer';
import Routes from './routes'

const App = () => (<div id="app">
		<DealerProvider>
			<SiteWrapper>
				<Header />
				<Routes />
				<Footer />
			</SiteWrapper>
		</DealerProvider>
	</div>
)

export default App;
