import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Link } from 'preact-router/match';
import { useDealer } from '../../providers/DealerProvider';

import style from './style.css';
import LogoImage from './assets/logo.png';

import DailyMinibusImage from './assets/daily-minibus.jpg';
import AfriwayBusImage from './assets/afriway-bus.jpg';

import DailyVanImage from './assets/daily-van.jpg';
import DailyChassisCabImage from './assets/daily-chassis-cab.jpg';
import DailyHiMaticImage from './assets/daily-hi-matic.jpg';

import EurocargoImage from './assets/eurocargo.jpg';

import StralisImage from './assets/stralis.jpg';
import TrakkerOnroadImage from './assets/trakker-onroad.jpg';

import StralisXWayImage from './assets/stralis-x-way.jpg';

import Daily4x4Image from './assets/daily-4x4.jpg';
import Eurocargo4x4Image from './assets/eurocargo-4x4.jpg';
import TrakkerOffroadImage from './assets/trakker-offroad.jpg';

import DailyCamperImage from './assets/daily-camper.jpg';

import SWayImage from './assets/s-way.jpg';
import TWayImage from './assets/t-way.jpg';

const Header = () => {
	const { dealer } = useDealer();
	const isRegular = !(dealer.type === "Service");

	return <header class={style.header}>
		<div class={style.top}>
			<Link href="/">
				<img src={LogoImage} alt="Iveco" />
			</Link>
			<span class={style.spring}></span>
			<h2 class={style.dealer}>{dealer.name}</h2>
		</div>
		<nav class={style.navigation}>
			<div class={style.navigationInner}>
				<ul class={style.navLevel1}>
					<li>
						<Link href="/">Home</Link>
					</li>

					{isRegular && <li>
						<Link>Products</Link>
						<ul class={style.navLevel2}>
							<li>
								<Link>Bus</Link>
								<ul class={style.navLevel3}>
									<li>
										<Link href="/daily-minibus">
											<img src={DailyMinibusImage} />
											<div>Daily Minibus</div>
										</Link>
									</li>
									{/*<li>
										<Link href="/afriway-bus">
											<img src={AfriwayBusImage} />
											<div>Afriway Bus</div>
										</Link>
									</li>*/}
								</ul>
							</li>
							<li>
								<Link>On Road</Link>
								<ul class={style.navLevel3}>
									<li class={style.border}>
										<span>MCV (3,5 - 8,5 t)</span>
										<ul class={style.navLevel4}>
											<li>
												<Link href="/daily-van">
													<img src={DailyVanImage} />
													<div>Daily Van</div>
												</Link>
											</li>
											<li>
												<Link href="/daily-chassis-cab">
													<img src={DailyChassisCabImage} />
													<div>Daily Chassis Cab</div>
												</Link>
											</li>
											<li>
												<Link href="/daily-hi-matic">
													<img src={DailyHiMaticImage} />
													<div>Daily Hi-Matic</div>
												</Link>
											</li>
										</ul>
									</li>
									<li class={style.border}>
										<span>HCV (8,5 - 16,5 t)*</span>
										<ul class={style.navLevel4}>
											<li>
												<Link href="/eurocargo">
													<img src={EurocargoImage} />
													<div>Eurocargo</div>
												</Link>
											</li>
										</ul>
									</li>
									<li>
										<span>XHCV (&lt16,5 t)</span>
										<ul class={style.navLevel4}>
											{/*<li>
												<Link href="/stralis">
													<img src={StralisImage} />
													<div>Stralis</div>
												</Link>
											</li>*/}
											<li>
												<Link href="/sway">
													<img src={SWayImage} />
													<div>S-Way</div>
												</Link>
											</li>
											{/*<li>
												<Link href="/new-trakker-onroad">
													<img src={TrakkerOnroadImage} />
													<div>Trakker</div>
												</Link>
											</li>*/}
										</ul>
									</li>
								</ul>
							</li>
							{/*<li>
								<Link>X-Way</Link>
								<ul class={style.navLevel3}>
									<li>
										<span>XHCV (&lt16,5 t)</span>
										<ul class={style.navLevel4}>
											<li>
												<Link href="/stralis-x-way">
													<img src={StralisXWayImage} />
													<div>Stralis X-Way</div>
												</Link>
											</li>
										</ul>
									</li>
								</ul>
							</li>*/}
							<li>
								<Link>Off Road</Link>
								<ul class={style.navLevel3}>
									<li class={style.border}>
										<span>MCV (3,5 - 8,5 t)</span>
										<ul class={style.navLevel4}>
											<li>
												<Link href="/daily-4x4">
													<img src={Daily4x4Image} />
													<div>Daily 4x4</div>
												</Link>
											</li>
										</ul>
									</li>
									<li class={style.border}>
										<span>HCV (8,5 - 16,5 t)</span>
										<ul class={style.navLevel4}>
											<li>
												<Link href="/eurocargo-4x4">
													<img src={Eurocargo4x4Image} />
													<div>Eurocargo 4x4</div>
												</Link>
											</li>
										</ul>
									</li>
									<li class={style.border}>
										<span>XHCV (&gt;16,5 t)</span>
										<ul class={style.navLevel4}>
											<li>
												<Link href="/tway">
													<img src={TWayImage} />
													<div>Iveco T-Way</div>
												</Link>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li>
								<Link>Camper</Link>
								<ul class={style.navLevel3}>
									<li>
										<Link href="/daily-camper">
											<img src={DailyCamperImage} />
											<div>Daily Camper</div>
										</Link>
									</li>
								</ul>
							</li>
						</ul>
					</li>}

					<li>
						<Link href="/promotions">Promotions</Link>
					</li>

					<li>
						<Link>Company</Link>
						<ul class={style.navLevel2}>
							<li>
								<Link href="/values">About Iveco</Link>
							</li>
							<li>
								<Link href="/sustainability">Sustainability</Link>
							</li>
						</ul>
					</li>

					{isRegular && <li>
						<Link href="/aftermarket-solutions">Aftermarket Solutions</Link>
					</li>}

					{isRegular && <li>
						<Link href="/contact-us">Contact Us</Link>
					</li>}
				</ul>
			</div>
		</nav>
	</header>
};

export default Header;
