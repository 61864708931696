import { h } from 'preact';
import { Router } from 'preact-router';

import { useDealer } from '../providers/DealerProvider';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Contact from '../routes/contact';
import Promotions from '../routes/promotions';
import Promotion from '../routes/promotion';
import Sustainability from '../routes/sustainability';
import Values from '../routes/values';
import TermsAndConditions from '../routes/terms-and-conditions';
import AftermarketSolutions from '../routes/aftermarket-solutions';

import DailyMinibus from '../routes/daily-minibus';
import AfriwayBus from '../routes/afriway-bus';
import DailyVan from '../routes/daily-van';
import DailyChassisCab from '../routes/daily-chassis-cab';
import DailyHiMatic from '../routes/daily-hi-matic';
import Eurocargo from '../routes/eurocargo';
import Daily4x4 from '../routes/daily-4x4';
import Eurocargo4x4 from '../routes/eurocargo-4x4';
import NewTrakkerOffroad from '../routes/new-trakker-offroad';
import DailyCamper from '../routes/daily-camper';
import Sway from '../routes/sway';
import Tway from '../routes/tway';

const Routes = () => {
	const { dealer } = useDealer();
	const isRegular = !(dealer.type === "Service");

	return <Router>
			{isRegular && <Home path="/" />}
			{!isRegular && <AftermarketSolutions path="/" />}
			<Contact path="/contact-us" />
			<Promotions path="/promotions" />
			<Promotion path="/promotion/:slug" />
			<Promotions path="/promotions" />
			<Sustainability path="/sustainability" />
			<Values path="/values" />
			<TermsAndConditions path="/terms-and-conditions" />
			<AftermarketSolutions path="/aftermarket-solutions" />

			<DailyMinibus path="/daily-minibus" />
			{/*<AfriwayBus path="/afriway-bus" />*/}
			<DailyVan path="/daily-van" />
			<DailyChassisCab path="/daily-chassis-cab" />
			<DailyHiMatic path="/daily-hi-matic" />
			<Eurocargo path="/eurocargo" />
			{/*<Stralis path="/stralis" />*/}
			{/*<NewTrackerOnroad path="/new-trakker-onroad" />*/}
			{/*<StralisXWay path="/stralis-x-way" />*/}
			<Daily4x4 path="/daily-4x4" />
			<Eurocargo4x4 path="/eurocargo-4x4" />
			<NewTrakkerOffroad path="/new-trakker-offroad" />
			<DailyCamper path="/daily-camper" />
			<Sway path="/sway" />
			<Tway path="/tway" />
		</Router>
}

export default Routes;
