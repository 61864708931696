import { h } from 'preact';
import style from './style.css';

const SiteWrapper = ({ children }) => (
	<div class={style.content}>
		{children}
	</div>
);

export default SiteWrapper;
