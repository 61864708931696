import { createContext } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { createClient } from 'contentful';
import Loading from '../components/loading';

const DealerContext = createContext('');

const client = createClient({
    space: "owdjhzkh3us0",
    accessToken: "aiugTdla7Im3GiOdO0n5MToVn0qjwxtCzJI6FOikQEE"
});

const DealerProvider = ({ children }) => {
    const [data, setData] = useState({
        dealerEntryId: null,
        dealer: null
    });

    useEffect(() => {
        const host = window.location.host;

        client.getEntries({
            content_type: 'dealer',
            'fields.host': host
        }).then(data => {
            if (data.total >= 0) {
                const dealerEntryId = data.items[0].sys.id;
                const dealer = data.items[0].fields;

                if (dealer.googleAnalyticsTrackingId && typeof window.gtag === 'function') {
                    window.gtag('config', dealer.googleAnalyticsTrackingId);
                }
                
                setData({dealerEntryId, dealer});
            }
        }).catch(error => {
            debugger;
        })
    }, []);

    const getPromotions = () => {
        return new Promise((res, rej) => {
            var promoFutures = [
                client.getEntries({
                    content_type: 'promotion',
                    links_to_entry: data.dealerEntryId
                }),
                client.getEntries({
                    content_type: 'promotion',
                    'fields.dealers[exists]': false
                })
            ];

            var promoFuture = Promise.all(promoFutures);

            promoFuture.then(data => {
                const first = data[0].items.map(entry => entry.fields);
                const second = data[1].items.map(entry => entry.fields);

                res(first.concat(second));
            }).catch(error => {
                rej(error);
            });
        });
    }

    const getPromotion = (slug) => {
        return new Promise((res, rej) => {
            client.getEntries({
                content_type: 'promotion',
                'fields.slug': slug
            }).then(data => {
                if (data.total >= 0) {
                    res(data.items[0].fields);
                } else {
                    res(null);
                }
            }).catch(error => {
                rej(error);
            });
        });
    }

    if (!data.dealer) {
        return <Loading />;
    }

    return <DealerContext.Provider value={{ dealer: data.dealer, dealerEntryId: data.dealerEntryId, getPromotions, getPromotion}} >
        {children}
    </DealerContext.Provider>
};

const useDealer = () => {
    return useContext(DealerContext);
}

export {
    DealerProvider,
    useDealer
}
